import { Dialog, Typography } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import React, { useState } from "react";
import AppButton from "./appButton";
import AppIconButton from "./appIconButton";
import { ReactComponent as SwapIcon } from "../assets/svgs/swap.svg";
import { COLORS } from "../utils/colors";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { RouteInfoV2 } from "../utils/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1))",
      background: "#ffffff",
      border: 0,
      borderRadius: 10,
      marginTop: 30,
      width: 240,
      height: 172,
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        width: 220,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    detailsContainer: {
      padding: 16,
    },
    title: {
      marginBottom: 4,
    },
    subtitle: {
      marginBottom: 12,
    },
    actionBar: {
      paddingTop: 18,
      paddingBottom: 18,
      paddingLeft: 24,
      paddingRight: 24,
      backgroundColor: "#F8F8F8",
      justifyContent: "space-between",
      display: "flex",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    start: {
      color: COLORS.green,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    dialogText: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const LargeCardV2 = (props: RouteInfoV2) => {
  const classes = useStyles();
  const {
    OutwardName,
    InwardName,
    RouteID,
    RouteNo,
    IsDisable,
    HeaderTitle,
    DetailTitle,
  } = props;
  const [direction, setDirection] = useState(0);
  const [open, setOpen] = React.useState(false);
  const disabled = Boolean(parseInt(IsDisable));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.detailsContainer}>
        <Typography variant="h4" className={classes.title}>
          <span className={classes.start}>
            {direction === 0
              ? OutwardName.split(" to ")[0]
              : InwardName.split(" to ")[0]}
          </span>{" "}
          to{" "}
          {direction === 0
            ? OutwardName.split(" to ").slice(1).join("")
            : InwardName.split(" to ").slice(1).join("")}
        </Typography>
        <Typography variant="h5" className={classes.title}>
          Route {RouteNo}
        </Typography>
      </div>
      <div className={classes.actionBar}>
        <AppIconButton
          color="primary"
          onClick={() => setDirection(direction === 0 ? 1 : 0)}
        >
          <SwapIcon />
        </AppIconButton>

        {disabled ? (
          <AppButton
            color="secondary"
            variant="contained"
            text="Live Info"
            onClick={handleClickOpen}
          />
        ) : (
          <AppButton
            href={`dashboard/${RouteNo}?direction=${direction}&routeId=${RouteID}`}
            color="primary"
            variant="contained"
            text="Live Info"
            disabled={disabled}
          />
        )}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          {handleClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogContent>
          <Typography variant="h3" gutterBottom>
            {HeaderTitle}
          </Typography>
          <Typography variant="h4" gutterBottom className={classes.dialogText}>
            {DetailTitle}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LargeCardV2;
