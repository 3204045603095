import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Fab,
  makeStyles,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { AllRoutes } from "../data/routes";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { COLORS } from "../utils/colors";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { ReactComponent as Arrow } from "../assets/svgs/arrow.svg";
import { orangeTheme } from "../components/appHeader";
import {
  BASE_URL,
  GetRealtimeInfoResponse,
  GetRouteGroupsResponse,
} from "../utils/api";
import RealTimeCardV2 from "../components/realtimeCardV2";

interface RouteParams {
  routeID: string;
  direction: string;
}

const AppTabs = withStyles({
  root: {
    padding: "0 20px",
  },
  indicator: {
    backgroundColor: COLORS.green,
  },
})(Tabs);

const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.white,
    filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1))",
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    maxWidth: 500,
  },
  outerContainer: {
    paddingBottom: 60,
    marginTop: 20,
  },
  title: {
    marginTop: 16,
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 8,
    fontWeight: 600,
  },
  notes: {
    color: "#5F5F5F",
    marginBottom: 8,
  },
  midStops: {
    color: "#5F5F5F",
    marginBottom: 8,
  },
  divider: {
    height: 1,
    backgroundColor: "#DCDCDC",
    marginLeft: 35,
  },
  start: {
    color: COLORS.green,
  },
  arrow: {
    marginLeft: 8,
    marginRight: 8,
  },
  legendContainer: { display: "flex", alignItems: "center" },
  realTime: {
    height: 34,
    width: 56,
    background: COLORS.green,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    marginRight: 8,
  },
  scheduledTime: {
    height: 34,
    width: 56,
    background: COLORS.white,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    marginRight: 8,
  },
  legend: {
    display: "flex",
    gap: "12px",
    marginBottom: 16,
  },
  floatingMenuButtonStyle: {
    position: "fixed",
    bottom: "5%",
    right: "5%",
  },
});

function useLocationQuery() {
  return new URLSearchParams(useLocation().search);
}

export const API_ROOT = "https://tmpanel.co.uk/api/RealTimeAPI";
export const API_KEY = "aLeUtBPYSDs4CsaIzQ5zYw==";

export default function Dashboard() {
  let { routeID: routeNo } = useParams<RouteParams>();
  let query = useLocationQuery();
  let history = useHistory();
  const directionQuery = query.get("direction");
  const routeId = query.get("routeId");
  const classes = useStyles();
  const [direction, setDirection] = React.useState(
    directionQuery ? parseInt(directionQuery) : 0
  );

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setTimeout(() => {
      console.log("Navigate back to home screen after 10 minutes");
      history.push("/");
    }, MINUTE_MS * 10);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: routeInfo } = useQuery<GetRouteGroupsResponse>(
    ["GetRouteGroup"],
    async () => {
      try {
        const res = await fetch(`${BASE_URL}GetRouteGroups`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          },
        });
        return res.json();
      } catch (e) {
        console.log(e);
      }
    }
  );

  let start = "";
  let finish = "";

  routeInfo?.Data.RouteGroupsList.some((group) => {
    const route = group.Routes.find((r) => r.RouteID === routeId);

    if (route) {
      start =
        direction === 0
          ? route.OutwardName.split(" to ")[0]
          : route.InwardName.split(" to ")[0];
      finish =
        direction === 0
          ? route.OutwardName.split(" to ").slice(1).join("")
          : route.InwardName.split(" to ").slice(1).join("");
      return true;
    }
    return false;
  });

  const { data: outwardData } = useQuery<GetRealtimeInfoResponse>(
    ["route", `${routeNo}`, "outward"],
    async () => {
      const res = await fetch(
        `${BASE_URL}GetRealtimeInfo?RouteID=${routeId}&JourneyType=0`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return res.json();
    },
    {
      // Refetch the data every 30 seconds
      refetchInterval: 30000,
      enabled: direction === 0,
      keepPreviousData: true,
    }
  );

  const { data: inwardData } = useQuery<GetRealtimeInfoResponse>(
    ["route", `${routeNo}`, "inward"],
    async () => {
      const res = await fetch(
        `${BASE_URL}GetRealtimeInfo?RouteID=${routeId}&JourneyType=11`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return res.json();
    },
    {
      // Refetch the data every 30 seconds
      refetchInterval: 30000,
      enabled: direction === 1,
      keepPreviousData: true,
    }
  );

  const route = AllRoutes.find((r) => r.routeNumber === routeNo);

  const outwardStops = outwardData?.Data.length && outwardData?.Data;
  const inwardStops = inwardData?.Data.length && inwardData?.Data;

  const inward = inwardStops;
  const outward = outwardStops;

  const stops = direction === 0 ? outward : inward;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setDirection(newValue);
  };

  const title = `${start} to ${finish} Real-Time Information is Now Live | JJ Kavanagh
  & Sons`;

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
      </Helmet>
      <Typography className={classes.title} variant="h1">
        <span className={classes.start}>{start}</span> to {finish}
      </Typography>
      <Typography className={classes.subtitle} variant="h3">
        Route {routeNo}
      </Typography>
      {route?.midStops && (
        <Typography className={classes.midStops} variant="h5">
          {start}
          <span className={classes.arrow}>
            <Arrow />
          </span>
          {route.midStops.map((stop) => (
            <span>
              {stop}
              <span className={classes.arrow}>
                <Arrow />
              </span>
            </span>
          ))}
          {finish}
        </Typography>
      )}
      {route?.notes && (
        <Typography className={classes.notes} variant="h5">
          {route.notes}
        </Typography>
      )}

      {
        <div className={classes.outerContainer}>
          <div className={classes.legend}>
            <div className={classes.legendContainer}>
              <div className={classes.realTime}></div>
              <Typography variant="h5">Real-time arrival time</Typography>
            </div>
            <div className={classes.legendContainer}>
              <div className={classes.scheduledTime}></div>
              <Typography variant="h5">Timetable arrival time</Typography>
            </div>
          </div>
          <div className={classes.container}>
            <AppTabs value={direction} onChange={handleChange}>
              <Tab label="Outward" />
              <Tab label="Inward" />
            </AppTabs>
            {!!stops &&
              stops?.map((route, index) => (
                <div key={route.Stage}>
                  <RealTimeCardV2
                    realTimeInfo={route}
                    isLast={index === stops.length - 1}
                  />
                  {index !== stops.length - 1 && (
                    <div className={classes.divider} />
                  )}
                </div>
              ))}
          </div>
        </div>
      }
      <ThemeProvider theme={orangeTheme}>
        <Fab
          className={classes.floatingMenuButtonStyle}
          color="primary"
          variant="extended"
          aria-label="Buy Tickets"
          // @ts-ignore
          target="_blank"
          href="https://ticketbooking.jjkavanagh.ie/BookTicket/TicketSelection"
        >
          Buy Tickets
        </Fab>
      </ThemeProvider>
    </Container>
  );
}
