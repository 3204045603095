import React, { useState } from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import { ReactComponent as MapIcon } from "../assets/svgs/map.svg";
import { ReactComponent as MapMarker } from "../assets/svgs/map-marker.svg";
import { ReactComponent as MapBusStop } from "../assets/svgs/bus-stop.svg";
import { COLORS } from "../utils/colors";
import {
  addMinutes,
  intervalToDuration,
  format,
  isToday,
  isTomorrow,
} from "date-fns";
import { RouteRealTimeInfo } from "../utils/api";

const useStyles = makeStyles({
  container: {
    display: "flex",
    padding: "0 0 0 25px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  timeContainer: {
    background: COLORS.white,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    color: COLORS.green,
    padding: 8,
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "center",
    minWidth: 80,
    position: "relative",
  },
  timeContainerLive: {
    background: COLORS.green,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    color: COLORS.white,
    padding: 8,
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "center",
    minWidth: 80,
    position: "relative",
  },
  sideContainer: {
    padding: "35px 16px",
    background: "#F8F8F8",
  },
  mapMarker: {
    position: "absolute",
    transform: "translate(-50%, -100%)",
  },
  textConatiner: {
    padding: "0 12px",
    justifyContent: "center",
    alignItems: "center",
    wordBreak: "break-word",
    flex: 1,
    overflow: "hidden",
  },
  timeDateContainer: {
    position: "absolute",
    bottom: -20,
    color: COLORS.grey,
    fontWeight: 400,
  },
  mapIcon: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
    backgroundColor: COLORS.white,
    borderRadius: 8,
  },
});

const Marker: React.FC<{ lat: number; lng: number }> = () => {
  const classes = useStyles();
  return (
    <div className={classes.mapMarker}>
      <MapMarker />
    </div>
  );
};

const BusStopMarker: React.FC<{ lat: number; lng: number }> = () => {
  const classes = useStyles();
  return (
    <div className={classes.mapMarker}>
      <MapBusStop />
    </div>
  );
};

const getInterval = (mins: string) => {
  const minutes = parseInt(mins);
  const duration = intervalToDuration({
    start: new Date(0),
    end: addMinutes(new Date(0), minutes),
  });
  return duration;
};

const getArrivalTimeDuration = (mins?: string) => {
  if (!mins) {
    return `N/A`;
  }
  const duration = getInterval(mins);

  if (duration && duration.days && duration.days > 0) {
    return getArrivalTime(mins);
  } else if (duration && duration.hours && duration.hours > 0) {
    return `${duration?.hours}hr${duration?.hours > 1 ? "s" : ""} ${
      duration?.minutes
    } mins`;
  } else {
    return `In ${duration?.minutes} mins`;
  }
};

const getArrivalTime = (mins?: string) => {
  if (!mins) {
    return "";
  }
  const minutes = parseInt(mins);
  const arrivalDate = addMinutes(new Date(), minutes);

  if (isToday(arrivalDate)) {
    return `Today, ${format(arrivalDate, "HH:mm")}`;
  } else if (isTomorrow(arrivalDate)) {
    return `Tomorrow, ${format(arrivalDate, "HH:mm")}`;
  } else {
    return format(arrivalDate, "do LLL, HH:mm");
  }
};

interface RealTimeCardV2Props {
  isLast?: boolean;
  realTimeInfo: RouteRealTimeInfo;
}

const RealTimeCardV2 = (props: RealTimeCardV2Props) => {
  const { realTimeInfo, isLast } = props;
  const stageName = realTimeInfo.Stage;
  const classes = useStyles();

  const stopLocation = {
    lat: realTimeInfo.StopLat,
    long: realTimeInfo.StopLng,
  };
  const busLocation =
    realTimeInfo.IsRealTime === "1"
      ? { lat: realTimeInfo.BusLat, long: realTimeInfo.BusLng }
      : null;
  const arrivalTime = realTimeInfo.InMin;

  const [showMap, setShowMap] = useState<boolean>(false);

  const apiIsLoaded = (map: any, maps: any) => {
    if (map && stopLocation && busLocation) {
      const bounds = new maps.LatLngBounds();
      bounds.extend({
        lat: parseFloat(stopLocation.lat),
        lng: parseFloat(stopLocation.long),
      });
      if (busLocation) {
        bounds.extend({
          lat: parseFloat(busLocation.lat),
          lng: parseFloat(busLocation.long),
        });
      }
      map.fitBounds(bounds);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div
          className={
            realTimeInfo.IsRealTime === "1"
              ? classes.timeContainerLive
              : classes.timeContainer
          }
        >
          {!realTimeInfo ? (
            <Typography variant="h5">loading...</Typography>
          ) : (
            <>
              <Typography variant="h5">
                {getArrivalTimeDuration(arrivalTime)}
              </Typography>
              {arrivalTime && !getInterval(arrivalTime).days && (
                <div className={classes.timeDateContainer}>
                  <Typography variant="h5">
                    {getArrivalTime(arrivalTime)}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        <div>{}</div>
        <div className={classes.textConatiner}>
          <Typography variant="h4">{stageName}</Typography>
        </div>
        <div
          className={classes.sideContainer}
          style={isLast ? { borderBottomRightRadius: 8 } : undefined}
        >
          <IconButton
            color="primary"
            className={classes.mapIcon}
            aria-label="show map"
            onClick={() => setShowMap(!showMap)}
          >
            <div>
              <MapIcon />
              <Typography color="textPrimary" variant="h5">
                Map
              </Typography>
            </div>
          </IconButton>
        </div>
      </div>
      {showMap && stopLocation && (
        <div style={{ height: "215px", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
            }}
            defaultCenter={{
              lat: parseFloat(stopLocation.lat),
              lng: parseFloat(stopLocation.long),
            }}
            defaultZoom={14}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          >
            <Marker
              lat={parseFloat(stopLocation.lat)}
              lng={parseFloat(stopLocation.long)}
            />
            {busLocation && (
              <BusStopMarker
                lat={parseFloat(busLocation.lat)}
                lng={parseFloat(busLocation.long)}
              />
            )}
          </GoogleMapReact>
        </div>
      )}
    </>
  );
};

export default RealTimeCardV2;
