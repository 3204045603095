import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as LogoStacked } from "../assets/svgs/logo-stacked.svg";

const AppLogo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  if (isMobile) {
    return <LogoStacked />;
  }

  if (isNotMobile) {
    return <Logo />;
  }

  return null;
};

export default AppLogo;
