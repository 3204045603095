import React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../utils/colors";

interface Props extends IconButtonProps {}

const useStyles = makeStyles({
  container: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
    width: 40,
    height: 40,
    backgroundColor: COLORS.white,
  },
});

const AppIconButton = (props: Props) => {
  const { children, ...otherProps } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={classes.container}
      aria-label="swap routes"
      {...otherProps}
    >
      {children}
    </IconButton>
  );
};

export default AppIconButton;
