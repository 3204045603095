import BankHolidays from "../data/bank-holidays.json";

import {
  differenceInCalendarWeeks,
  isSunday,
  format,
  nextMonday,
  startOfTomorrow,
  isBefore,
} from "date-fns";

const isCollegeSummerTerm = () => {
  return isBefore(new Date(), new Date(2022, 8, 18));
};

const isTodayABankHoliday = () => {
  const today = format(new Date(), "yyyy-MM-dd");
  return !!BankHolidays.find((holiday) => holiday.date === today);
};

const bankHolidayHeader = "Sorry this service is not active today.";

const bankHolidayText = `We do not run this service on Bank Holiday Mondays. The next bus will operate tomorrow ${format(
  startOfTomorrow(),
  "do LLL"
)}.`;

export interface RouteInfo {
  finish: string;
  start: string;
  midStops?: Array<string>;
  routeNumber: string;
  notes: string;
  outInactive?: Array<number>;
  inInactive?: Array<number>;
  outDropOff?: Array<number>;
  inDropOff?: Array<number>;
  disable?: boolean;
  disableText?: string;
  disableHeader?: string;
  useRealtime?: boolean;
}

export const MainRoutes: Array<RouteInfo> = [
  {
    finish: "Dublin Airport",
    start: "Clonmel",
    routeNumber: "717",
    notes: "Monday - Sunday",
    outInactive: [22, 17],
    inInactive: [15],
    outDropOff: [14, 15, 16, 18],
    inDropOff: [1],
  },
  // {
  //   finish: "Dublin Airport",
  //   start: "Naas",
  //   routeNumber: "737",
  //   notes: "Monday - Sunday",
  //   outInactive: [13, 10],
  //   inInactive: [7, 12, 6],
  //   outDropOff: [5, 12, 6, 7, 8],
  //   inDropOff: [11, 4, 3, 14, 2, 1],
  // },
  {
    finish: "Dublin Airport",
    start: "Waterford",
    routeNumber: "736",
    notes: "Monday - Sunday",
    outInactive: [23],
    inInactive: [24, 27],
    outDropOff: [21, 22, 24, 29, 25],
    inDropOff: [4, 3, 28, 26, 2, 1],
  },
  {
    finish: "Dublin Airport",
    start: "Limerick",
    routeNumber: "735",
    notes: "Monday - Sunday",
    outInactive: [17],
    inInactive: [14],
    outDropOff: [13, 16, 14, 36, 19],
    inDropOff: [22, 2, 33, 1],
  },
];

const is871Disabled = () => {
  return (
    isSunday(new Date()) ||
    differenceInCalendarWeeks(new Date(), new Date(2021, 3, 4), {
      weekStartsOn: 1,
    }) %
      2 ===
      0
  );
};

export const LocalRoutes: Array<RouteInfo> = [
  {
    finish: "Carlow",
    start: "Portlaoise",
    routeNumber: "825",
    notes: "Monday - Saturday",
    disable: isTodayABankHoliday(),
    disableText: bankHolidayText,
    disableHeader: bankHolidayHeader,
  },
  // {
  //   finish: "Kilkenny",
  //   start: "Carlow",
  //   routeNumber: "873",
  //   notes: "Monday - Saturday",
  //   outInactive: [9],
  //   inInactive: [7],
  //   disable: isTodayABankHoliday(),
  //   disableText: bankHolidayText,
  //   disableHeader: bankHolidayHeader,
  // },
  {
    finish: "Kilkenny",
    start: "Urlingford",
    routeNumber: "871",
    notes: "Monday - Saturday",
    disable: is871Disabled() || isTodayABankHoliday(),
    disableText: isTodayABankHoliday()
      ? bankHolidayText
      : `We only run this service every second week, Monday - Saturday. The next bus will operate on ${format(
          nextMonday(new Date()),
          "do LLL"
        )}.`,
    disableHeader: bankHolidayHeader,
  },
  {
    finish: "Carlow",
    start: "Hacketstown",
    routeNumber: "874",
    notes: "Monday - Saturday",
    outInactive: [12, 25],
    inInactive: [8, 10],
    disable: isTodayABankHoliday(),
    disableText: bankHolidayText,
    disableHeader: bankHolidayHeader,
  },
  {
    finish: "Athy",
    start: "Maynooth",
    routeNumber: "NUM06",
    notes: "Monday - Saturday",
  },
];

const collegeSummerHeader = `Sorry this College Service is finished for the Summer.`;
const collegeSummerText = `We only run this service during the College Term, Monday - Friday. This service will resume on September 19th.`;

export const CollegeRoutes: Array<RouteInfo> = [
  {
    finish: "Limerick",
    start: "Carlow",
    routeNumber: "CRLM",
    notes: "Friday and Sunday (College Term Only)",
    inInactive: [5],
    disable: isCollegeSummerTerm(),
    disableText: collegeSummerText,
    disableHeader: collegeSummerHeader,
  },
  // {
  //   finish: "Maynooth University",
  //   start: "Kilkenny",
  //   routeNumber: "N12",
  //   stops: RouteN12.filter((route) => route.timePoint === "No"),
  // },
  // {
  //   finish: "Maynooth",
  //   start: "Portlaoise",
  //   routeNumber: "N08",
  //   stops: RouteN08.filter((route) => route.timePoint === "No"),
  // },
  // {
  //   finish: "Maynooth",
  //   start: "Athy",
  //   routeNumber: "N06",
  //   stops: RouteN06.filter((route) => route.timePoint === "No"),
  // },
  {
    finish: "Carlow",
    start: "Curragh",
    routeNumber: "ITC5",
    notes: "Monday - Friday (College Term Only)",
    disable: isCollegeSummerTerm() || isTodayABankHoliday(),
    disableText: isCollegeSummerTerm() ? collegeSummerText : bankHolidayText,
    disableHeader: isCollegeSummerTerm()
      ? collegeSummerHeader
      : bankHolidayHeader,
  },
  {
    finish: "Carlow",
    start: "Mountmellick",
    routeNumber: "ITC4",
    notes: "Monday - Friday (College Term Only)",
    disable: isCollegeSummerTerm() || isTodayABankHoliday(),
    disableText: isCollegeSummerTerm() ? collegeSummerText : bankHolidayText,
    disableHeader: isCollegeSummerTerm()
      ? collegeSummerHeader
      : bankHolidayHeader,
  },
  {
    finish: "Carlow",
    start: "Monasterevin",
    routeNumber: "ITC2",
    notes: "Monday - Friday (College Term Only)",
    disable: isCollegeSummerTerm() || isTodayABankHoliday(),
    disableText: isCollegeSummerTerm() ? collegeSummerText : bankHolidayText,
    disableHeader: isCollegeSummerTerm()
      ? collegeSummerHeader
      : bankHolidayHeader,
  },
  {
    finish: "Carlow",
    start: "Naas",
    routeNumber: "736A",
    notes: "Monday - Friday (College Term Only)",
    disable: isCollegeSummerTerm() || isTodayABankHoliday(),
    disableText: isCollegeSummerTerm() ? collegeSummerText : bankHolidayText,
    disableHeader: isCollegeSummerTerm()
      ? collegeSummerHeader
      : bankHolidayHeader,
  },
];

// export const TownRoutes: Array<RouteInfo> = [
//   {
//     finish: "Clane",
//     start: "Naas",
//     routeNumber: "846O",
//     notes: "",
//     outInactive: [16, 17, 18],
//     inInactive: [6, 15],
//     disable: isTodayABankHoliday(),
//     disableText: bankHolidayText,
//     disableHeader: bankHolidayHeader,
//   },
//   {
//     finish: "Kilcullen",
//     start: "Newbridge",
//     routeNumber: "129O",
//     notes: "",
//     outInactive: [5, 6, 8, 11, 14],
//     inInactive: [23, 15, 7, 4],
//     disable: isTodayABankHoliday(),
//     disableText: bankHolidayText,
//     disableHeader: bankHolidayHeader,
//   },
// ];

export const WaterfordRoutes: Array<RouteInfo> = [
  {
    finish: "Abbey Park",
    start: "Ballygunner",
    midStops: ["Ardkeen", "City Center", "Ferrybank"],
    routeNumber: "607",
    notes: "Monday - Saturday",
    useRealtime: true,
  },
  {
    finish: "Slieverue",
    start: "Ballygunner",
    midStops: ["Ardkeen", "City Center", "Ferrybank"],
    routeNumber: "617",
    notes: "Monday - Saturday",
    useRealtime: true,
  },
  {
    finish: "City Center",
    start: "Ballygunner",
    midStops: ["Ardkeen"],
    routeNumber: "627",
    notes: "Monday - Saturday (Via Dunmore Road)",
    useRealtime: true,
  },
];

export const AllRoutes = [
  ...MainRoutes,
  ...LocalRoutes,
  ...CollegeRoutes,
  ...WaterfordRoutes,
];
