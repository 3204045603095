const PRODUCTION_BASE_URL_V2 =
  "https://TMPanelExternalAPI.TMPanel.co.uk/Realtime/";
// const PRODUCTION_BASE_URL =
//   "https://ticketbooking.jjkavanagh.ie/MobileAPI/Realtime/";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/MobileAPI/Realtime/"
    : PRODUCTION_BASE_URL_V2;

export interface RouteInfoV2 {
  RouteID: string;
  RouteNo: string;
  OutwardName: string;
  InwardName: string;
  IsDisable: "0" | "1";
  HeaderTitle: string;
  DetailTitle: string;
}

export interface GetRouteGroupsResponse {
  Status: string;
  Message: string;
  Data: {
    RouteGroupsList: Array<{
      GroupName: string;
      Routes: Array<RouteInfoV2>;
    }>;
  };
}

export interface RouteRealTimeInfo {
  Stage: string;
  StopLat: string;
  StopLng: string;
  LeavingIn: string;
  IsRealTime: "0" | "1";
  BusLat: string;
  BusLng: string;
  InMin: string;
}

export interface GetRealtimeInfoResponse {
  Status: string;
  Message: string;
  Data: Array<RouteRealTimeInfo>;
}
