import React from "react";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";

interface Props extends ButtonProps {
  text?: string;
}

const useStyles = makeStyles({
  container: {
    borderRadius: 5,
  },
});

const AppButton = (props: Props) => {
  const { text, children, ...allProps } = props;
  const classes = useStyles();
  return (
    <Button className={classes.container} {...allProps}>
      {text || children}
    </Button>
  );
};

export default AppButton;
