import React from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import "./App.css";
import { theme } from "./utils/theme";
import {
  AppBar,
  Container,
  IconButton,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import AppHeader from "./components/appHeader";
import AppLogo from "./components/appLogo";

import { ReactComponent as BackButton } from "./assets/svgs/back-button.svg";

import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider } from "react-cookie";

// Create a client
const queryClient = new QueryClient();

ReactGA.initialize("UA-21345175-1", {
  titleCase: false,
});

const useStyles = makeStyles({
  container: {
    background: "#FCFCFC",
  },
  header: {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: {
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
  },
});

function usePageViews() {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

function App() {
  const classes = useStyles();

  usePageViews();

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <div className={classes.container}>
            <AppBar position="static" color="transparent" elevation={0}>
              <Container className={classes.header}>
                <Switch>
                  <Route exact path="/">
                    <Link to="/">
                      <AppLogo />
                    </Link>
                  </Route>
                  <Route path="/dashboard/:routeID">
                    <Link to="/">
                      <IconButton
                        className={classes.backButton}
                        aria-label="back to homepage"
                      >
                        <BackButton />
                      </IconButton>
                    </Link>
                  </Route>
                </Switch>
                <AppHeader />
              </Container>
            </AppBar>

            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/dashboard/:routeID">
                <Dashboard />
              </Route>
            </Switch>
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
